<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-row wrap>
      <v-col cols="12" md="8">
        <!-- Search Option -->
        <v-layout>
          <v-row wrap>
              <v-col cols="12">
              <v-text-field
                  v-model="search"
                  outlined
                  label="Search for a product..."
                  dense
                  append-icon="mdi-magnify"
                  single-line
              ></v-text-field>
              </v-col>
          </v-row>
        </v-layout>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="allProducts"
          item-key="name"
          class="elevation-1"
          :search="search"
          show-select
        >
          <template
            v-slot:item.image="{ item }"
            style="width:100px !important;"
          >
            <td>
              <p>
                <v-img
                  class="ma-2"
                  width="60"
                  height="60"
                  :src="item.productImagePath"
                  contain
                />
              </p>
            </td>
          </template>

          <template v-slot:item.discount="{ item }">
            <v-layout wrap>
              <span style="color:#BD2121">{{ item.discount }}</span>
            </v-layout>
          </template>

          <template v-slot:item.prescription="{ item }">
                <v-layout wrap v-if="item.needsPrescription == false">
                  <span style="color:#72bd61">No</span>
                </v-layout>
                <v-layout v-if="item.needsPrescription !== true">
                  <span style="color:#BD2121">Yes</span>
                </v-layout>
              </template>

          <template v-slot:item.actions="{ item }">
            <v-btn class="buttonStyle mr-5" small  @click="addPosCart(item)">
              Add
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" md="4" class="mt-15">
          <v-card class="mt-5">
              <v-layout wrap>
                  <v-col cols="12">
                      <h5>Customer's Cart</h5>
                      <hr/>
                      <v-layout wrap>
                          <v-col cols="12">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">Name</th>
                                      <th class="text-left">Price</th>
                                      <th class="text-left">Qty</th>
                                      <th class="text-left">Total</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="item in cartItems" :key="item.name">
                                      <td>
                                        <p class="text-h6" style="font-size:10px !important;">{{ item.name }}</p></td>
                                      <td>
                                        <p class="text-h6" style="font-size:10px !important;">{{ systemSetting.systemCurrency }}{{ item.price }} </p>
                                      </td>
                                      <td>
                                        <v-icon dark color="#72bd61"  style="font-size:14px !important;" @click="decrementCounter(item)">mdi-minus-circle</v-icon>
                                        <v-layout style="font-size:10px !important;">{{ item.quantity }}</v-layout>
                                        <v-icon dark color="#72bd61" style="font-size:14px !important;" @click="incrementCounter(item)">mdi-plus-circle</v-icon>
                                      </td>
                                      <td>
                                        <v-layout wrap>
                                          <v-col cols="9">
                                            <p class="text-h6" style="font-size:10px !important;">{{ systemSetting.systemCurrency }}{{ item.price * item.quantity }}</p>
                                          </v-col>
                                          <v-col cols="3">
                                            <v-btn icon color="#DB2C2C"  @click="deleteProduct(item)">
                                              <v-icon style="font-size:10px !important;">mdi-close</v-icon>
                                            </v-btn>
                                          </v-col>
                                        </v-layout>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                          </v-col>
                      </v-layout>
                     
                      <v-layout v-if="cartItems.length > 0">
                        <hr/>
                        <v-row>
                          <v-col cols="12">
                            <span class="text-h4 mr-5" style="font-size:16px !important;"><b>Total</b></span> 
                              <span class="text-h4" style="color:#F22121; font-size:16px !important;"><b> 
                                  {{ systemSetting.systemCurrency }} {{ totalAmount .toFixed(2) }}</b>
                              </span>
                          </v-col>
                        </v-row>
                      </v-layout>
                      <v-layout v-if="cartItems.length > 0">
                        <v-row>
                          <v-col cols="12">
                            <v-progress-linear
                            color="#7CBF46"
                            v-show="loader"
                            :indeterminate="true"
                            ></v-progress-linear>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-btn  class="buttonStyleWarning" @click="clearCart">
                              Clear
                            </v-btn>
                          </v-col>  

                          <v-col cols="6">
                            <v-btn  class="buttonStyle" @click="proceedOrder">
                              Order
                            </v-btn>
                          </v-col>
                          
                        </v-row>
                      </v-layout>

                  </v-col>
              </v-layout>
          </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogPrint" width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
                    Order Details
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogPrint = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
          </v-card-title>
          <v-card-text>
            <v-layout wrap ref="printContent">
              <v-row  style="border-bottom:1px solid black; margin-bottom:10px;">
                <v-col cols="12">
                            <table class="table table-striped">
                              <tr>
                                <th>{{storeDetail.companyName}}</th>
                                <th></th>
                                <th></th>
                              </tr>
                              <tr>
                                <th>{{userDetails.address}}, {{ userDetails.country }}</th>
                                <th></th>
                                <th></th>
                              </tr>
                              <tr>
                                <th>{{ currentDateTime }}</th>
                                <th></th>
                                <th></th>
                              </tr>
                              <tr class="mt-4">
                                <th>Product Name</th>
                                <th>Product Price</th>
                                <th>Quantity</th>
                              </tr>
                              <tr v-for="(order, index) in cartItems"
                                :key="index">
                                  <td>{{order.name}}</td>
                                  <td>{{ systemSetting.systemCurrency}}{{order.price}}</td>
                                  <td>{{order.quantity}}</td>
                              </tr>
                              <tr>
                                <th>Total Paid:</th>
                                <th>{{ systemSetting.systemCurrency}}{{totalAmount .toFixed(2)}}</th>
                                <th></th>
                              </tr>
                            </table>
                </v-col>
              </v-row>

                         
              <v-row>
                            <v-col cols="6">
                              <v-btn  class="buttonStyleWarning" @click="clearCart">
                                Clear
                              </v-btn>
                            </v-col>  

                            <v-col cols="6">
                              <v-btn  class="buttonStyle" @click="printDialog">
                                Print Slip
                              </v-btn>
                            </v-col>
              </v-row>
            </v-layout>
          </v-card-text>
        </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import moment from 'moment';

export default {
  name: "RetailerPos",

  data: () => ({
    dialogPrint: false,
    loader: false,
    xf:[],
    btnloading: false,
    userDetails:{
      isActive:true,
    },
    updateProduct: false,
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/#/",
      },
      {
        text: "My Account",
        disabled: false,
        href: "/#/overview/retailer_dashboard/home",
      },
      {
        text: "Point of Sale",
        disabled: true,
        href: "/#/retailer/0/pos",
      },
    ],
    userId: "",
    search: "",
    selected: [],
    allProducts: [],
    errorMessage: [],
    cartItems:[],
    cartItemsData:[],
    systemSetting: {
      systemCurrency: "GH₵",
    },
    storeDetail:{},
    total: 0,
  }),
  computed: {
    headers() {
      return [
        {
          text: "Image",
          value: "image",
        },
        { text: "Name", value: "name" },
        { text: "Price", value: "price" },
        { text: "Quantity", value: "quantity" },
        { text: "Prescription", value: "needsPrescription" },
        { text: "Discount", value: "discount" },
        { text: "Action", value: "actions" },
      ];
    },
    totalAmount() {
      return this.cartItems.reduce((total, item) => {
        return total + (item.price * item.quantity);
      }, 0);
    },
    currentDateTime() {
      return moment().format('YYYY-MM-DD HH:mm:ss');
    }
  },
  created() {
    this.userId = localStorage.getItem("userId");
    this.getUserDetails();
    this.getUserProducts();
  },
  methods: {
    getUserDetails() {
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + localStorage.getItem("userId"))
        .then((response) => {
          console.log(response);
          this.storeDetail = response.data.data.Retailer;
          this.userDetails = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getUserProducts() {
      this.$http
        .get(`${process.env.VUE_APP_URL}product/user/` + this.userId)
        .then((response) => {
          if (response.data.success == true) {
            this.allProducts = response.data.data.reverse();
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    proceedOrder() {
        this.loader = true;
          this.$http
            .post(`${process.env.VUE_APP_URL}sale/${this.userId}`, this.cartItemsData)
            .then((response) => {
                console.log(response);
                this.loader = false;
                this.dialogPrint = true;
            })
            .catch((err) => {
                console.log(err);
                this.loader = false;
            });
    },

    addPosCart(item) {
    // Assuming 'item' is an object representing the product
    const existingItemIndex = this.cartItems.findIndex(cartItem => cartItem.name === item.name);

    if (existingItemIndex !== -1) {
      // If the item already exists in the cart, increment its quantity
      this.cartItems[existingItemIndex].quantity++;
    } 
    else 
    {
      // If the item doesn't exist in the cart, add it
      this.cartItems.push({
        productId: item.productId,
        name: item.name,
        price: item.price,
        quantity: 1, // Assuming the initial quantity is 1
      });

      this.cartItemsData.push({
        productId: item.productId,
        quantity: 1,
      });
    }
    },

    incrementCounter(item) {
      item.quantity = item.quantity + 1;
    },

    decrementCounter(item) {
      if (item.quantity <= 0) {
        item.quantity = 1;
      }
      item.quantity = item.quantity - 1;
    },

    deleteProduct(item) {
      const index = this.cartItems.findIndex(cartItem => cartItem.name === item.name);
      if (index !== -1) {
        this.cartItems.splice(index, 1); // Remove the item from the array
      }
    },
    clearCart() {
      this.cartItems = []; // Empty the cartItems array
      this.dialogPrint = false;
    },
    refreshPage() {
      location.reload();
    },
    printDialog() {
      // Get the content to print
      let printContent = this.$refs.printContent;

      // Clone the content to avoid modifying the original dialog
      let printWindow = window.open('', '_blank');
      printWindow.document.open();
      printWindow.document.write('<html><head><title>Print</title></head><body onload="window.print();">' + printContent.innerHTML + '</body></html>');
      printWindow.document.close();
    },
  },
};
</script>

<style scoped>
.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 20px;
}

.orderStatBox {
  padding: 30px !important;
  width: 120px;
  height: 80px;
  border-radius: 10px;
  text-align: center;
  margin: 10px;
}

.orderStatText {
  color: #c2c2c2;
  font-size: 16px;
  text-align: center;
}

.buttonStyle {
margin-top: 10px;
width: auto;
height: auto;
background: #72bd61;
border-radius: 10px;
color: #ffffff !important;
font-size: 10px;
}


.buttonStyleWarning {
  margin-top: 10px;
  width: auto;
  height: auto;
  background: #111212;
  border-radius: 10px;
  color: #000000 !important;
  font-size: 10px;
}
</style>
